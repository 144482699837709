import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { CardType } from 'src/app/core/enums/card-type.enum';
import { Fee } from 'src/app/core/models/fee.model';
import { OKResponse } from 'src/app/core/models/ok-response.model';
import { LanguageService } from 'src/app/core/services/language.service';
import { PurchaseService } from 'src/app/shared/services/purchase.service';
import { environment } from 'src/environments/environment';

export interface BuyCard {
	feeSelected: Fee | null;
	feeDetails: Fee | null;
	roamingData: any;
	fees: Fee[];
	feesDetails: any[];
}

export interface UpdateOrder {
	payment_method?: string;
	product_id?: string;
	product_total?: number;
	order_total?: number;
}

@Injectable()
export class BuyCardService {
	protected http: HttpClient;
	colors = ['', 'green', 'purple', 'pink'];
	private feeSubject = new BehaviorSubject<BuyCard>({
		feeSelected: null,
		feeDetails: null,
		roamingData: null,
		fees: [],
		feesDetails: [],
	});
	private physicalSimFees = new BehaviorSubject<BuyCard>({
		feeSelected: null,
		feeDetails: null,
		roamingData: null,
		fees: [],
		feesDetails: [],
	});
	private eSimFees = new BehaviorSubject<BuyCard>({
		feeSelected: null,
		feeDetails: null,
		roamingData: null,
		fees: [],
		feesDetails: [],
	});
	private langChangeSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	fees$: Observable<BuyCard> = this.feeSubject.asObservable();
	langChangeSubject$: Observable<boolean> = this.langChangeSubject.asObservable();

	constructor(
		http: HttpClient,
		private readonly purchaseService: PurchaseService,
		private readonly languageService: LanguageService,
	) {
		this.http = http;
	}

	setLangChangeSubject(value: boolean) {
		this.langChangeSubject.next(value);
	}

	getLangChangeSubject(): boolean {
		return this.langChangeSubject.value;
	}

	getFees(type: string): Observable<Fee[]> {
		const lang = this.languageService.getLanguage();
		return this.http.post<OKResponse>(environment.apiUrl + `/product/list`, { type, lang, currently_valid: 1 }).pipe(
			map((fees) =>
				fees.info!.data!.map((fee: Fee, index: number) => {
					const colorIndex = index % this.colors.length;
					return {
						...fee,
						tagColor: `item__tarifa item__tarifa--${this.colors[colorIndex]}`,
					} as Fee;
				}),
			),
		);
	}

	getFee(productId: string): Observable<any> {
		const lang = this.languageService.getLanguage();
		return this.http.get<any>(environment.apiUrl + `/product/${productId}?lang=${lang}`);
	}

	getFeeDetails(productId: string): Observable<any> {
		const lang = this.languageService.getLanguage();
		return this.http
			.get<any>(environment.apiUrl + `/product/${productId}?lang=${lang}`)
			.pipe(
				map((fee) => ({ ...fee, details: fee.details.map((detail: any) => ({ ...detail, icon: `icon-${detail.icon}` })) })),
			);
	}

	getRoamingDetails(bonusId: string): Observable<any> {
		const lang = this.languageService.getLanguage();
		const params = new HttpParams().set('lang', lang!);
		return this.http.get<any>(environment.apiUrl + `/bonus/${bonusId}`, { params });
	}

	getRoaming(): Observable<any> {
		return this.http.post<any>(environment.apiUrl + `/bonus/list`, {});
	}

	createOrder() {
		const order = this.purchaseService.getPurchase();
		const lang = this.languageService.getLanguage();
		return this.http.post<any>(environment.apiUrl + `/order/create`, {
			product_id: order.productId,
			sim_count: order.feeCount,
			product_subtotal: 0,
			product_total: order.feeAmount,
			bonus_id: order.bonusId,
			bonus_subtotal: 0,
			bonus_total: order.bonusId ? order.feeAmount : 0,
			order_subtotal: 0,
			order_total: order.totalAmount,
			email_copy: order.emailCopy,
			email: order.email,
			payment_method: order.paymentMethod,
			lang,
			type: order.cardType === 0 ? 'eSIM' : CardType[order.cardType ?? 1],
		});
	}

	updateOrder(payment?: string, product_id?: string, product_total?: number, order_total?: number) {
		const { orderId } = this.purchaseService.getPurchase();
		let body: UpdateOrder = {};

		if (payment) body.payment_method = payment;
		if (product_id) body.product_id = product_id;
		if (product_total) body.product_total = product_total;
		if (order_total) body.order_total = order_total;

		return this.http.post<any>(environment.apiUrl + `/order/${orderId}/update`, body);
	}

	updateProduct(orderId: string, product_id: string, type?: string): Observable<any> {
		return this.http.post<any>(environment.apiUrl + `/order/${orderId}/update-product`, {
			product_id,
			type: type || undefined,
		});
	}

	preaprePhysicCard(order_id: string | undefined): Observable<any> {
		return this.http.post<any>(environment.apiUrl + `/sim-dispenser/prepare`, { order_id });
	}

	launchPhysicCard(order_id: string | undefined): Observable<any> {
		return this.http.post<any>(environment.apiUrl + `/sim-dispenser/dispense`, { order_id, dispense: true });
	}

	eSIMOrangeActivation(order_id: string | undefined): Observable<any> {
		return this.http.post<any>(environment.apiUrl + `/orange/esim-tramitation`, { order_id });
	}

	eSIMOrangeStatus(order_id: string | undefined): Observable<any> {
		return this.http.post<any>(environment.apiUrl + `/orange/esim-tramitation-status`, { order_id });
	}

	launchActivationSim(order_id: string): Observable<any> {
		return this.http.post<any>(environment.apiUrl + `/orange/prepaid-tramitation`, { order_id });
	}

	checkActivationStatus(order_id: string): Observable<any> {
		return this.http.post<any>(environment.apiUrl + `/orange/prepaid-tramitation-status`, { order_id });
	}

	rechargeOrangeActivation(order_id: string): Observable<any> {
		return this.http.post<any>(environment.apiUrl + `/orange/disa-recharge`, { order_id });
	}

	rechargeOrangeStatus(order_id: string): Observable<any> {
		return this.http.post<any>(environment.apiUrl + `/orange/disa-recharge-status`, { order_id });
	}

	setFees(fees: Fee[]) {
		this.feeSubject.next({ ...this.feeSubject.value, fees });
	}

	setPhysicalFees(fees: Fee[]) {
		this.physicalSimFees.next({ ...this.physicalSimFees.value, fees });
	}

	setESimFees(fees: Fee[]) {
		this.eSimFees.next({ ...this.eSimFees.value, fees });
	}

	getPhysicalFees(): any {
		return this.physicalSimFees.value;
	}

	getEsimFees(): any {
		return this.eSimFees.value;
	}

	setFeeSelected(feeSelected: Fee) {
		this.feeSubject.next({ ...this.feeSubject.value, feeSelected });
	}

	setFeeDetails(feeDetails: Fee) {
		this.feeSubject.next({ ...this.feeSubject.value, feeDetails });
	}

	getFeeDetailsSelected() {
		return this.feeSubject.value.feeDetails;
	}

	setRoamingData(roamingData: any) {
		this.feeSubject.next({ ...this.feeSubject.value, roamingData });
	}

	setFeesDetails(feesDetails: any) {
		this.feeSubject.next({ ...this.feeSubject.value, feesDetails });
	}

	getFeesDetails() {
		return this.feeSubject.value.feesDetails;
	}

	getFeesSubject() {
		return this.feeSubject.value;
	}
}
