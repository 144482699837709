import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root',
})
export class LanguageService {
	private readonly defaultLanguage = 'es';
	private readonly allowedLanguages = ['es', 'en'];
	private selectedLanguageSubject = new BehaviorSubject<string | undefined>(undefined);
	selectedLanguage$ = this.selectedLanguageSubject.asObservable();
	private allLanguageSubject = new BehaviorSubject<any>([]);
	allLanguages$ = this.allLanguageSubject.asObservable();

	private subscription$!: Subscription;

	constructor(
		private translateService: TranslateService,
		private http: HttpClient,
	) {}

	setLanguage(lang: string) {
		localStorage.setItem('langKiosko', lang!);
		this.selectedLanguageSubject.next(lang);
	}

	getLanguage() {
		return this.selectedLanguageSubject.value;
	}

	setAllLanguages(langs: any) {
		this.allLanguageSubject.next(langs);
	}

	getAllLanguage(): any {
		return this.allLanguageSubject.value;
	}

	changeLanguage(lang: string) {
		this.setLanguage(lang);
		this.selectedLanguage$.subscribe((language) =>
			language ? this.translateService.use(language) : this.translateService.setDefaultLang(lang),
		);
	}

	autoResolveLanguage() {
		this.translateService.addLangs(this.allowedLanguages);
		const lang = localStorage.getItem('langKiosko')
			? localStorage.getItem('langKiosko')
			: this.translateService.getBrowserLang();
		localStorage.setItem('langKiosko', lang!);
		this.changeLanguage(lang ?? this.defaultLanguage);
		if (this.subscription$) this.subscription$.unsubscribe();
	}

	getAllLanguages(): Observable<any> {
		return this.http.post<any>(environment.apiUrl + `/get-languages`, {});
	}
}
