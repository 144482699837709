import { Component } from '@angular/core';
import { ErrorService } from '../../services/error.service';

@Component({
	selector: 'app-error',
	templateUrl: './error.component.html',
	styleUrls: ['./error.component.scss'],
})
export class ErrorComponent {
	public statusCodePayError: string[] = [
		'101',
		'102',
		'104',
		'106',
		'107',
		'109',
		'110',
		'112',
		'114',
		'116',
		'117',
		'118',
		'119',
		'120',
		'121',
		'122',
		'123',
		'124',
		'125',
		'126',
		'129',
		'130',
		'160',
		'161',
		'167',
		'TPV-PC_EMV0007',
	];

	constructor(public errorService: ErrorService) {}

	ngOnInit(): void {}

	checkCodeStatus(): string {
		let statusCode = '';

		this.statusCodePayError.forEach((code) => {
			if (this.errorService.getErrorPayCause().includes(code)) statusCode = code;
		});

		// if (statusCode === '' && this.errorService.getErrorPayCause().includes('TPV')) {
		// 	statusCode = 'TPV-PC_EMV0007';
		// }

		return statusCode;
	}
}
