import { Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Observable, Subject, takeUntil } from 'rxjs';
import Keyboard from 'simple-keyboard';
import { startValidationNavigation } from 'src/app/core/components/nav/nav.component';
import { CardType } from 'src/app/core/enums/card-type.enum';
import { IdentityType } from 'src/app/core/enums/identity-type.enum';
import { AccessibilityService } from 'src/app/shared/services/accessibility.service';
import { ContractService } from 'src/app/shared/services/contract.service';
import { ErrorService } from 'src/app/shared/services/error.service';
import { KeyboardService } from 'src/app/shared/services/keyboard.service';
import { LoadingBlockService } from 'src/app/shared/services/loading-block.service';
import { Purchase, PurchaseService } from 'src/app/shared/services/purchase.service';
import { ScannerService } from 'src/app/shared/services/scanner.service';
import { SseService } from 'src/app/shared/services/sse.service';
import { StepperService } from 'src/app/shared/services/stepper.service';
import { TimeoutService } from 'src/app/shared/services/timeout.service';
import { environment } from 'src/environments/environment';
import { BuyCardService } from '../../services/buy-card.service';

@Component({
	selector: 'app-contract',
	templateUrl: './contract.component.html',
	styleUrls: ['./contract.component.scss'],
	host: {
		class: 'main',
	},
})
export class ContractComponent implements OnInit, OnDestroy {
	@ViewChild('inputEmail', { static: false }) inputEmail!: ElementRef;
	@ViewChild('inputRepeatEmail', { static: false }) inputRepeatEmail!: ElementRef;
	fieldName: string = 'inputEmail';

	step = 1;
	form: FormGroup;
	value = '';
	keyboard!: Keyboard;
	contractPdfSource: any;
	policyPdfSource: any;
	inputName!: string;
	purchase$!: Observable<Purchase>;
	destroy$ = new Subject<void>();
	IdentityType = IdentityType;
	identityOption!: IdentityType;
	showValidDocs: boolean = false;
	phaseDocumentIdentity: number = 1;
	phasePassport: number = 1;
	CardType = CardType;
	timeoutId: any;
	option!: IdentityType;
	order_id: any;
	email: string = '';
	repeat_email: string = '';
	emailMatch: boolean = true;
	activeInput: string = 'phone';

	constructor(
		private readonly fb: FormBuilder,
		private readonly router: Router,
		private readonly route: ActivatedRoute,
		readonly purchaseService: PurchaseService,
		readonly contractService: ContractService,
		readonly keyboardService: KeyboardService,
		private readonly renderer: Renderer2,
		private el: ElementRef,
		readonly errorService: ErrorService,
		readonly stepperService: StepperService,
		readonly accessibilityService: AccessibilityService,
		private readonly timeoutService: TimeoutService,
		private buycardService: BuyCardService,
		private sseService: SseService,
		readonly loadingBlockService: LoadingBlockService,
		private readonly scannerService: ScannerService,
	) {
		this.form = this.fb.group({
			email: ['', [Validators.required, Validators.email]],
			repeat_email: ['', [Validators.required, Validators.email]],
			feeCount: [0],
		});

		this.timeoutService.clearAllTimeouts();
	}

	ngOnInit(): void {
		this.loadingBlockService.setIsLoading(true);
		this.route.params.pipe(takeUntil(this.destroy$)).subscribe({
			next: (value: Params) => {
				this.feeCountFormControl.patchValue(this.purchaseService.getPurchase().feeCount);
				this.step = value ? Number(value['step']) : 1;
				if (this.step !== 3 && this.keyboardService.getKeyboarData().isShow) {
					this.renderer.removeClass(this.el.nativeElement, 'form-page');
					this.keyboardService.setKeyboarData({ isShow: false });
				}
				if (this.step === 6) {
					startValidationNavigation(this.router, this.timeoutService);
				}

				if (this.step === 1) {
					const productId = this.purchaseService.getPurchase().productId ?? '';
					this.loadingBlockService.setIsLoading(true);
					this.contractService.getDocumentByProduct(productId).subscribe((v) => {
						this.contractPdfSource = this._base64ToArrayBuffer(v.pdf);
						this.loadingBlockService.setIsLoading(false);
					});
				}
				if (this.step === 5) {
					this.loadingBlockService.setIsLoading(true);
					this.contractService.getDocument().subscribe((v) => {
						this.policyPdfSource = this._base64ToArrayBuffer(v.pdf);
						this.loadingBlockService.setIsLoading(false);
					});
				}

				if (this.step === 3) {
					this.keyboardService.show();
					//comprobar si existe el correo en el purchase y ponerlo
					if (this.purchaseService.getPurchase().email && this.purchaseService.getPurchase().email !== '') {
						this.form.patchValue({
							email: this.purchaseService.getPurchase().email!,
							repeat_email: this.purchaseService.getPurchase().email!,
						});
					}
					this.renderer.addClass(this.el.nativeElement, 'form-page');
				}
				if ((this.step === 2 || this.step === 4) && !!this.purchaseService.getPurchase().isDistinctProduct!) {
					this.router.navigate(['/buy-card/contract/step/9']);
				}
				if (this.step === 2) this.el.nativeElement.scrollTop = 0;

				//Descomentar este codigo si finalmente se decide dejar el email cuando es otro product_id
				// if (this.step === 2 && !!this.purchaseService.getPurchase().isDistinctProduct!)
				// 	this.router.navigate(['/buy-card/contract/step/3']);

				if (this.step === 9 || this.step === 7) {
					this.phaseDocumentIdentity = 1;
					this.phasePassport = 1;
					this.scannerService.setScannerStatus(false);
				}
			},
			error: () => (this.step = 1),
		});

		this.errorService.hasError$.pipe(takeUntil(this.destroy$)).subscribe({
			next: (value) => {
				value
					? this.renderer.addClass(this.el.nativeElement, 'main--loader')
					: this.renderer.removeClass(this.el.nativeElement, 'main--loader');
			},
			error: () => this.renderer.removeClass(this.el.nativeElement, 'main--loader'),
		});

		this.step =
			Number(this.route.snapshot.paramMap.get('step')) === 0 ? 1 : Number(this.route.snapshot.paramMap.get('step'));

		this.keyboardService.keyboard$.pipe(takeUntil(this.destroy$)).subscribe({
			next: (value) => {
				this.form.patchValue(value.formValues);

				if (this.activeInput === 'email') {
					this.email = value.formValues.email;
				} else if (this.activeInput === 'repeat_email') {
					this.repeat_email = value.formValues.repeat_email;
				}
				this.validateEmail();
			},
			error: () => {},
		});

		this.scannerService.isScannerActivate$.pipe(takeUntil(this.destroy$)).subscribe({
			next: (value) => {
				if (value) {
					this.option === IdentityType.Passport
						? this.scannerService.activateScanner('passport').subscribe()
						: this.scannerService.activateScanner('nif').subscribe();
				}
			},
			error: () => this.scannerService.activateScanner('nif').subscribe(),
		});

		this.keyboardService.keyboardEvent.pipe(takeUntil(this.destroy$)).subscribe({
			next: (fieldFocused) => {
				console.log('entro');
				this.onFocus(fieldFocused, this.fieldName);
			},
		});
	}

	validateEmail(): void {
		if (
			this.email &&
			this.repeat_email &&
			(this.email.length === this.repeat_email.length || this.repeat_email.length > 6)
		) {
			this.emailMatch = this.email === this.repeat_email;
		} else {
			this.emailMatch = true;
		}
	}

	// setActiveInput(inputName: string) {
	// 	this.activeInput = inputName;
	// }

	goToPolicy() {
		this.step = 5;
		this.router.navigate(['/buy-card/contract/step', this.step]);
	}

	onFocus(input: string, viewChild: any) {
		this.activeInput = input;
		this.fieldName = viewChild;
		this.keyboardService.setKeyboarData({ fieldFocused: input });

		if (viewChild === 'inputEmail' || viewChild === 'inputRepeatEmail') {
			console.log('entro al if');
			const input =
				viewChild === 'inputEmail'
					? (this.inputEmail.nativeElement as HTMLInputElement)
					: (this.inputRepeatEmail.nativeElement as HTMLInputElement);
			console.log('input', input);
			input.setSelectionRange(input.value.length, input.value.length);
			input.focus();
		}

		if (!this.keyboardService.getKeyboarData().isShow) this.keyboardService.show();
	}

	removeRoaming() {
		const totalAmount = this.purchaseService.getPurchase()?.totalAmount ?? 0;
		this.purchaseService.setPurchase({
			totalAmount: totalAmount / 2,
			remainingAmount: totalAmount / 2,
			isRomaingActivated: false,
			roamingTotal: 0,
			romaingCount: 0,
		});
	}

	deleteField(fieldName: string) {
		fieldName === 'email' ? this.emailFormControl.patchValue(null) : this.repeatEmailFormControl.patchValue(null);
		this.keyboardService.setKeyboarData({ fieldFocused: fieldName, resetValues: true, formValues: { [fieldName]: '' } });
	}

	_base64ToArrayBuffer(base64: any) {
		const binary_string = window.atob(base64);
		const len = binary_string.length;
		const bytes = new Uint8Array(len);
		for (let i = 0; i < len; i++) {
			bytes[i] = binary_string.charCodeAt(i);
		}
		return bytes.buffer;
	}

	startSequencePassport() {
		this.timeoutService.clearAllTimeouts();
		this.phasePassport = 1;
		const intervals = [6000, 4000, 4000];

		const executeTimeouts = (index: number) => {
			if (index >= intervals.length && this.phasePassport !== 4 && this.phasePassport !== 5) {
				index = 0; // Reiniciar el índice
			}
			if (this.phasePassport !== 4 && this.phasePassport !== 5) this.phasePassport = index + 1;

			this.timeoutId = setTimeout(() => {
				executeTimeouts(index + 1);
			}, intervals[index]);
			this.timeoutService.setTimeout(this.timeoutId);
		};

		executeTimeouts(0);
	}

	startSequenceDNIFront() {
		this.timeoutService.clearAllTimeouts();
		this.phaseDocumentIdentity = 1;
		const intervals = [6000, 5000, 5000];

		const executeTimeouts = (index: number) => {
			if (
				this.phaseDocumentIdentity === 4 ||
				this.phaseDocumentIdentity === 5 ||
				this.phaseDocumentIdentity === 6 ||
				this.phaseDocumentIdentity === 7 ||
				this.phaseDocumentIdentity === 8
			) {
				return;
			} else {
				if (index >= intervals.length) {
					index = 0;
				}
				this.phaseDocumentIdentity = index + 1;
				this.timeoutId = setTimeout(() => {
					executeTimeouts(index + 1);
				}, intervals[index]);
				this.timeoutService.setTimeout(this.timeoutId);
			}
		};

		executeTimeouts(0);
	}

	startSequenceDNIBack() {
		this.timeoutService.clearAllTimeouts();
		const intervals = [6000, 4000, 5000, 7000, 5000, 5000];

		const executeTimeouts = (index: number) => {
			if (this.phaseDocumentIdentity === 7 || this.phaseDocumentIdentity === 8) {
				return;
			}
			if (index >= intervals.length) {
				index = 3;
			}
			this.phaseDocumentIdentity = index + 1;
			this.timeoutId = setTimeout(() => {
				executeTimeouts(index + 1);
			}, intervals[index]);
			this.timeoutService.setTimeout(this.timeoutId);
		};

		executeTimeouts(3);
	}

	executeSSEFlow(order_id: any, type: any) {
		const url = `${environment.apiUrl}/scanner/check-scan-status/${order_id}/${type}`;
		const options = {};
		const eventNames = ['message'];
		if (type === 'passport') {
			this.sseService
				.connectToServerSentEvents(url, options, eventNames)
				.pipe(takeUntil(this.destroy$))
				.subscribe({
					next: (status: any) => {
						let data = JSON.parse(status.data);
						if (data.success && data.success === true) {
							this.sseService.close();
							this.phasePassport = 4;
							this.contractService.verifyIdentityOrange(order_id, 'passport').subscribe({
								next: (verify) => {
									this.purchaseService.setPurchase({ isTPVActive: verify.tpv_active });

									//SI MSG = KO o DATA = NULL, LANZAMOS ERROR
									if (!!verify.error && verify.error.error_code !== '') {
										this.phasePassport = 1;
										this.errorService.setError();
										this.errorService.setErrorCause(verify.error.error_code);
									}
									//SI ES NULL, VERIFICAMOS COMO TRUE Y DEVOLVEMOS EL OK
									else if (verify.msg && verify.msg === 'ok') {
										//set in purchase correct verification
										this.purchaseService.setPurchase({ isClientVerified: true });

										this.phasePassport = 5;
										const goToPay = setTimeout(() => {
											this.phasePassport = 1;
											this.router.navigate(['buy-card/contract/step', 9]);
										}, 3000);
										this.timeoutService.setTimeout(goToPay);
									} else {
										//SI NO ES NULO, DEVOLVEMOS VISTA DE ERROR, YA QUE SE ENTIENDE QUE HAY UN ERROR CON EL DOCUMENTO (CADUCADO, NO VALIDO...)
										this.phasePassport = 1;
										this.errorService.setErrorCause(verify.error.error_code);
										this.errorService.setError();
									}
								},
								error: () => {
									this.errorService.setError();
									this.sseService.close();
								},
							});
						}
						//SI EL PROCESO TERMINA POR TIMEOUT, DEVOLVEMOS ERROR
						else if (data?.success === false && data?.message.includes('Wait time exceeded')) {
							this.errorService.setErrorCause('TIME_OUT');
							this.errorService.setError();
							this.sseService.close();
						}
					},
					error: () => {
						this.errorService.setErrorCause('TIME_OUT');
						this.errorService.setError();
						this.sseService.close();
					},
				});
		} else if (type === 'nif') {
			this.sseService
				.connectToServerSentEvents(url, options, eventNames)
				.pipe(takeUntil(this.destroy$))
				.subscribe({
					next: (status: any) => {
						let data = JSON.parse(status.data);
						if (data.nif_side_1 && this.phaseDocumentIdentity <= 3) {
							this.scannerService.setScannerStatus(false);
							this.scannerService.secondNIFPart(true);
							this.startSequenceDNIBack();
						}
						if (data.success && this.phaseDocumentIdentity > 3 && this.phaseDocumentIdentity < 7) {
							this.sseService.close();
							this.phaseDocumentIdentity = 7;
							this.contractService.verifyIdentityOrange(order_id, 'nif').subscribe({
								next: (verify) => {
									this.purchaseService.setPurchase({ isTPVActive: verify.tpv_active });
									//SI MSG = KO o DATA = NULL, LANZAMOS ERROR
									if (!!verify.error && verify.error.error_code !== '') {
										this.phaseDocumentIdentity = 1;
										this.errorService.setError();
										this.errorService.setErrorCause(verify.error.error_code);
									} else if (verify.msg === 'ok' || !verify.data.document.classificationCause) {
										//set in purchase correct verification
										this.purchaseService.setPurchase({ isClientVerified: true });

										this.phaseDocumentIdentity = 8;
										const goToPay = setTimeout(() => {
											this.phaseDocumentIdentity = 1;
											this.router.navigate(['buy-card/contract/step', 9]);
										}, 3000);
										this.timeoutService.setTimeout(goToPay);
									} else {
										//SI NO ES NULO, DEVOLVEMOS VISTA DE ERROR, YA QUE SE ENTIENDE QUE HAY UN ERROR CON EL DOCUMENTO (CADUCADO, NO VALIDO...)
										this.phaseDocumentIdentity = 1;
										this.errorService.setErrorCause(verify.error.error_code);
										this.errorService.setError();
										this.sseService.close();
									}
								},
								error: () => {
									this.errorService.setErrorCause('TIME_OUT');
									this.errorService.setError();
									this.sseService.close();
								},
							});
						}
						//SI EL PROCESO TERMINA POR TIMEOUT, DEVOLVEMOS ERROR
						else if (data?.success === false && data?.message.includes('Wait time exceeded')) {
							this.errorService.setErrorCause('TIME_OUT');
							this.errorService.setError();
							this.sseService.close();
						}
					},
					error: () => {
						this.errorService.setErrorCause('TIME_OUT');
						this.errorService.setError();
						this.sseService.close();
					},
				});
		}
	}

	selectOption(step: number, option: IdentityType) {
		this.step = step;
		this.identityOption = option;
		this.option = option;

		const purchase = this.purchaseService.getPurchase();

		//OPTION === 0 => PASAPORTE
		if (option === 0) {
			if (purchase && !!purchase.orderId) {
				this.startSequencePassport();
				this.executeSSEFlow(purchase.orderId, 'passport');
			} else {
				this.buycardService.createOrder().subscribe({
					next: (data) => {
						this.order_id = data.info.order_id;
						this.purchaseService.setPurchase({ orderId: data.info.order_id });
						this.startSequencePassport();
						this.executeSSEFlow(this.order_id, 'passport');
					},
					error: () => this.errorService.setError(),
				});
			}
		}
		//OPTION === 1 => DNI
		if (option === 1) {
			if (purchase && !!purchase.orderId) {
				this.startSequenceDNIFront();
				this.executeSSEFlow(purchase.orderId, 'nif');
			} else {
				this.buycardService.createOrder().subscribe({
					next: (data) => {
						this.order_id = data.info.order_id;
						this.purchaseService.setPurchase({ orderId: data.info.order_id });
						this.startSequenceDNIFront();
						this.executeSSEFlow(this.order_id, 'nif');
					},
					error: () => this.errorService.setError(),
				});
			}
		}
		this.router.navigate(['buy-card/contract/step', step]);
	}

	showValidDocuments(value: boolean) {
		this.showValidDocs = value;
		this.contractService.setShowValidDocuments(value);
	}

	navigateToFeesList() {
		this.stepperService.setIsOnPurchaseResume(true);
		this.router.navigate(['/buy-card/2']);
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
		if (this.timeoutId) {
			clearTimeout(this.timeoutId);
		}
		this.timeoutService.clearAllTimeouts();
	}

	get emailFormControl(): FormControl {
		return this.form.get('email') as FormControl;
	}

	get repeatEmailFormControl(): FormControl {
		return this.form.get('repeat_email') as FormControl;
	}

	get feeCountFormControl(): FormControl {
		return this.form.get('feeCount') as FormControl;
	}
}
