<!-- ERRORES GENERICOS -->
<div
	*ngIf="
		(errorService.hasError$ | async) &&
		(errorService.getErrorCause() === '' || errorService.getErrorCause() === 'TIME_OUT') &&
		!errorService.getErrorTicketCause() &&
		errorService.getErrorPayCause() === '' &&
		!errorService.getErrorQRCause()
	"
	class="loader"
>
	<img src="../../../../assets/styles/resources/error.svg" alt="" />
	<div class="loader__text">
		<span>{{ 'ERROR.GENERAL_ERROR' | translate }}</span>
		<span class="loader__text__description">{{ 'ERROR.RETRY_PETITION' | translate }}</span>
	</div>

	<!-- ERRORES GENERALES -->
	<ng-template #showGeneric>
		<img src="../../../../assets/styles/resources/error.svg" alt="" />
		<div class="loader__text">
			<span>{{ 'ERROR.GENERAL_ERROR' | translate }}</span>
			<span class="loader__text__description">{{ 'ERROR.RETRY_PETITION' | translate }}</span>
		</div>
	</ng-template>
</div>

<!-- CONTROL ERRORES VERIFICACION IDENTIDAD -->
<div class="loader" *ngIf="errorService.getErrorCause().includes('EC') || errorService.getErrorCause().includes('E1')">
	<img
		*ngIf="errorService.getErrorCause() === 'TECHNICAL_ISSUE_SCANNER'"
		src="../../../../assets/styles/resources/scan-error.svg"
		alt=""
	/>

	<img
		*ngIf="
			errorService.getErrorCause() === 'E1141' ||
			errorService.getErrorCause() === 'E1142' ||
			errorService.getErrorCause() === 'E1143' ||
			errorService.getErrorCause() === 'E1144' ||
			errorService.getErrorCause() === 'E1145' ||
			errorService.getErrorCause() === 'E1146'
		"
		src="../../../../assets/styles/resources/error.svg"
		alt=""
	/>
	<img
		*ngIf="errorService.getErrorCause() === 'SCAN_NOT_DETECTED' || errorService.getErrorCause() === 'E11101'"
		src="../../../../assets/styles/resources/scan-2.svg"
		alt=""
	/>
	<img
		*ngIf="
			errorService.getErrorCause() === 'E11100' ||
			errorService.getErrorCause() === 'E1111' ||
			errorService.getErrorCause() === 'E1110' ||
			errorService.getErrorCause() === 'E11102' ||
			errorService.getErrorCause() === 'E11103' ||
			errorService.getErrorCause() === 'E11104'
		"
		src="../../../../assets/styles/resources/document_error.svg"
		alt=""
	/>
	<div class="loader__text">
		<!-- MAIN COMMENT -->
		<span>{{ 'INVALID_DOCUMENTS.' + errorService.getErrorCause() | translate }}</span>

		<!-- SECONDARY COMMENT -->
		<span *ngIf="errorService.getErrorCause() === 'SCAN_NOT_DETECTED'" class="loader__text__description">
			{{ 'INVALID_DOCUMENTS.SECONDARY_MESSAGES.LOCATE_IN_SCANNER' | translate }}
		</span>
		<span *ngIf="errorService.getErrorCause() === 'TECHNICAL_ISSUE_SCANNER'" class="loader__text__description">
			{{ 'INVALID_DOCUMENTS.SECONDARY_MESSAGES.GENERIC_SUB_MESSAGE' | translate }}
		</span>
		<span *ngIf="errorService.getErrorCause() === 'E11100'" class="loader__text__description">
			{{ 'INVALID_DOCUMENTS.SECONDARY_MESSAGES.GENERIC_SUB_MESSAGE' | translate }}
		</span>
		<span *ngIf="errorService.getErrorCause() === 'E11101'" class="loader__text__description">
			{{ 'INVALID_DOCUMENTS.SECONDARY_MESSAGES.ANVERSE_REPEAT' | translate }}
		</span>
		<span *ngIf="errorService.getErrorCause() === 'E1111'" class="loader__text__description">
			{{ 'INVALID_DOCUMENTS.SECONDARY_MESSAGES.REPEAT_PROCESS' | translate }}
		</span>
		<span *ngIf="errorService.getErrorCause() === 'E1110'" class="loader__text__description">
			{{ 'INVALID_DOCUMENTS.SECONDARY_MESSAGES.NOT_CONTINUE' | translate }}
		</span>
		<span *ngIf="errorService.getErrorCause() === 'E11102'" class="loader__text__description">
			{{ 'INVALID_DOCUMENTS.SECONDARY_MESSAGES.USE_VALID_DOCUMENT' | translate }}
		</span>
		<span *ngIf="errorService.getErrorCause() === 'E11103'" class="loader__text__description">
			{{ 'INVALID_DOCUMENTS.SECONDARY_MESSAGES.USE_VALID_DOCUMENT_2' | translate }}
		</span>
		<span *ngIf="errorService.getErrorCause() === 'E11104'" class="loader__text__description">
			{{ 'INVALID_DOCUMENTS.SECONDARY_MESSAGES.DOCUMENT_PLUS_18' | translate }}
		</span>

		<span
			*ngIf="
				errorService.getErrorCause() !== 'SCAN_NOT_DETECTED' &&
				errorService.getErrorCause() !== 'TECHNICAL_ISSUE_SCANNER' &&
				errorService.getErrorCause() !== 'E11100' &&
				errorService.getErrorCause() !== 'E11101' &&
				errorService.getErrorCause() !== 'E1111' &&
				errorService.getErrorCause() !== 'E1110' &&
				errorService.getErrorCause() !== 'E11102' &&
				errorService.getErrorCause() !== 'E11103' &&
				errorService.getErrorCause() !== 'E11104'
			"
			class="loader__text__description"
			>{{ 'INVALID_DOCUMENTS.SECONDARY_MESSAGES.GENERIC_SUB_MESSAGE' | translate }}</span
		>
	</div>
</div>

<!-- CONTROL ERRORES DE TICKETS DE COMPRA -->
<div class="loader" *ngIf="(errorService.hasError$ | async) && errorService.getErrorTicketCause()">
	<img src="../../../../assets/styles/resources/error-print-ticket.svg" alt="" />
	<div *ngIf="errorService.getErrorCause() === ''" class="loader__text">
		<span>{{ 'ERROR.NOT_PRINT_TICKET' | translate }}</span>
		<span class="loader__text__description">{{ 'ERROR.TICKET_BY_EMAIL_OR_ORANGE_STAFF' | translate }}</span>
	</div>
</div>

<!-- CONTROL ERRORES DE TICKETS QR ESIM -->
<div class="loader" *ngIf="(errorService.hasError$ | async) && errorService.getErrorQRCause()">
	<img src="../../../../assets/styles/resources/error-print-ticket.svg" alt="" />
	<div class="loader__text">
		<span>{{ 'ERROR.NOT_PRINT_TICKET' | translate }}</span>
		<span class="loader__text__description">{{ 'ERROR.TICKET_BY_EMAIL_OR_ORANGE_STAFF' | translate }}</span>
	</div>
</div>

<!-- CONTROL ERRORES DE PAGO CON TARJETA Y EFECTIVO -->
<div
	class="loader"
	*ngIf="
		(errorService.hasError$ | async) &&
		(errorService.getErrorPayCause().includes('1') || errorService.getErrorPayCause() === 'CASH')
	"
>
	<img src="../../../../assets/styles/resources/error-pay.svg" alt="" />
	<div *ngIf="errorService.getErrorCause() === ''" class="loader__text">
		<span>{{ 'ERROR.NOT_POSSIBLE_PAY' | translate }}</span>
		<span *ngIf="errorService.getErrorPayCause() === 'CASH'; else showErrorCard" class="loader__text__description">{{
			'ERROR.PAY_CARD_OR_RETRY_CASH' | translate
		}}</span>
		<ng-template #showErrorCard>
			<span class="loader__text__description">{{ 'CARD_DECLINE_CODES.' + checkCodeStatus() | translate }}</span>
		</ng-template>
	</div>
</div>

<!-- CONTROL ERRORES RECARGA -->
<div class="loader" *ngIf="(errorService.hasError$ | async) && errorService.getErrorCause() === 'Recarga no realizada'">
	<img src="../../../../assets/styles/resources/error.svg" alt="" />
	<div class="loader__text">
		<span>{{ 'ERROR.GENERAL_ERROR' | translate }}</span>
		<span class="loader__text__description">{{ 'ERROR.RETRY_PETITION' | translate }}</span>
	</div>
</div>

<!-- FINISH PROCESS -->
<div class="loader" *ngIf="(errorService.hasError$ | async) && errorService.getErrorPayCause() === 'FINISH_PROCESS'">
	<img src="../../../../assets/styles/resources/error-pay.svg" alt="" />
	<div *ngIf="errorService.getErrorCause() === ''" class="loader__text">
		<span>{{ 'ERROR.NOT_POSSIBLE__COMPLETE_PAY' | translate }}</span>
		<span class="loader__text__description">{{
			'INVALID_DOCUMENTS.SECONDARY_MESSAGES.GENERIC_SUB_MESSAGE' | translate
		}}</span>
	</div>
</div>
